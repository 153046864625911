var anchoventana=$(document).width();
var altoventana=$(document).height();
var md=768;

(function($) {
  var Sage = {
    'common': {
      init: function() {
        /**
         * declaracion variables globales
         */

         /**
          * init plugins y listeners comunes de proyectos
          */
         initPlugins();
         initListeners();

        /**
         * js del proyecto
        */
        $('.js-close').on('click', function(){
          $(this).closest('.alert').hide();
        });

        $('.js-categoria').on('click', function(){
          //$(this).find('.capa').toggleClass('categoria-activa');
          _cat = $(this).attr('data-categoria');
          
          if (!$(this).find('.capa').hasClass('categoria-activa')){
            $(this).find('.capa').addClass('categoria-activa');
            $('#input_'+_cat).val(1);
          }
          else{
            $(this).find('.capa').removeClass('categoria-activa');
            $('#input_'+_cat).val('');
          }
        });
        
        $('#formCookies').submit();
        initGA();

        $('.js-configurar').on('click', function(){
            $('.js-formulario-cookies').show();
        });
        $('.tab-filtro').on('click',function(){
          $(this).find(".js_tipo-red").trigger("click");
        });
        $('.js_tipo-red').on('click', function(e){
            e.stopPropagation();
            if ($(this).hasClass('js-tiporedactiva')){
                console.log("### >>> elmino activa");

                //si está activada la desactivo
                $('.js_tipo-red').removeClass('js-tiporedactiva');
                $('.js_tipo-red').parent().removeClass('selected');
                $('.js-red').removeClass('js-redinactiva');
            }
            else{//activar tipo porque ya estaba activado
               
                //desactivo los 3 botones y activo solo el clickado
                $('.js_tipo-red').removeClass('js-tiporedactiva');
                $('.js_tipo-red').parent().removeClass('selected');
                $(this).addClass('js-tiporedactiva');
                $(this).parent().addClass('selected');
                
                if ($(this).attr('data-tipored') == 'IN'){
                  $('.js-red[data-in!="1"]').addClass('js-redinactiva');
                  $('.js-red[data-in="1"]').removeClass('js-redinactiva');
                  $('#tipo_in').val(1);
                  $('#tipo_out').val(0);
                }
                else{
                  if ($(this).attr('data-tipored') == 'OUT'){
                    $('.js-red').addClass('js-redinactiva');
                    $('.js-red[data-out="1"]').removeClass('js-redinactiva');
                    $('#tipo_in').val(0);
                    $('#tipo_out').val(1);
                  }
                  else{
                    $('.js-red').removeClass('js-redinactiva');
                    $('#tipo_in').val(1);
                    $('#tipo_out').val(1);
                  }
                }
            }   
            $('.js-red').removeClass('js-seleccionada');

            setTimeout(function(){
              checkAnchoItems();
            },0);
        });
        function checkAnchoItems(){
          if(anchoventana>md){
            anchoItem=$(".js-red:first-child").width()+15;
            totalItems=$(".js-red").not(".js-redinactiva").length;
            valorMaximo=(totalItems-2)*anchoItem;
            if($(window).width()>totalItems*anchoItem){
              valorMaximo="100%";
            }
            $(".js-contenedor-network").css("max-width",valorMaximo);
          }
        }
        checkAnchoItems();

        $('.js-empezar').on('click', function(){
          if (parseInt($('#numcategorias').val())==1 ){
            if (parseInt($('#numsubcategorias').val())==1){
              document.location.href = "centros.php";
            }
            else{
              document.location.href = "planes.php";
            }  
          }
          else{
            document.location.href = "redes.php";
          }  
        });

        $('.js-red').on('click', function(){
          var red_sel = $(this).attr('data-red');
        //if (!$(this).hasClass('js-redinactiva')){ //si está inactiva, no se puede seleccionar
            if ($(this).hasClass('js-seleccionada')){
                //si ya estaba seleccionada --> quitar
                $(this).removeClass('js-seleccionada');
                $('#red-'+red_sel).val('0');
            }
            else{//no estaba seleccionada --> seleccionar
                $(this).addClass('js-seleccionada');
                $('#red-'+red_sel).val('1');
            }
          //}  
        });

        $('.js-idiomas').on('change', function(){
          var _datos = 'idioma='+$(this).val();
          var _redirect = $(this).attr('data-redirect');
            $.ajax({
              url: "ajax/ajax-idioma.php",
							type: "POST",
							dataType: "json",
							data: _datos,
              success: function (response) {
                //console.log('pp');
                //location.reload(); 
                document.location.href = _redirect+".php";
              },
              error: function (xhr, status) {
              },
              complete: function (xhr, status) {
              }        
            });
        });

        var mySwiperCarousel = new Swiper('.swiper-carousel', {
          loop: false,
          slidesPerView: 6,
          autoplay: {
            delay: 2000,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true 
          },
          navigation: {
            nextEl: '#swiper-button-next-carousel',
            prevEl: '#swiper-button-prev-carousel',
          },
          breakpoints: {
            640: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }
        });

        var mySwiperTestimonials = new Swiper('.swiper-testimonials', {
          loop: false,
          slidesPerView: 1,
          autoplay: {
            delay: 5000,
          },          
          pagination: {
            el: '.swiper-pagination',
            clickable: true 
          },
          navigation: {
            nextEl: '#swiper-button-next-testimonials',
            prevEl: '#swiper-button-prev-testimonials',
          },
        });

        $( window ).resize(function() {
          checkAnchoItems();
        });

      },
      finalize: function() {
      }
    },

    'planes' : {
      init: function(){
        $(".filtro").click(function(){
          id=$(this).attr('id');
          $(".filtro").not('#' + id).removeClass("activo");
          $(this).toggleClass("activo");
          //$("body").toggleClass("hidescroll");
        })
      },
      finalize:function(){
        
      }
    }

  };
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

function resultadoComprobarCodigo(obj,target, result, spinner_holder){
 // console.log(result);
  if (result['resultado'] == 'OK' ){


    $('#end_redemption_at').html(result['end_redemption_at']);
        

      if (!result['cambiar_dominio']){    
        //MIXPANEL
        if (typeof mixpanel != "undefined") {
          mixpanel.register({
            "Client Name": 'sss',
            "Promotion ID": '00'
          });
        }
        if (result['validado']){
          //document.location.href="planes.php";
              if (parseInt(result['num_categorias']) == 1){
                  var _catsel = result['categoria_sel'];//$( ".red-input" ).attr('id')+',';
                  
                  setCookie("tlcpromo_in", '', 365);
                  setCookie("tlcpromo_out", '', 365);

                  setCookie("tlcpromo_categorias", _catsel+',', 365);

                  if (parseInt(result['num_subcategorias']) == 1){
                      var _subcatsel = result['subcategoria_sel'];
                      setCookie("tlcpromo_subcategorias", _subcatsel+',', 365);
                      document.location.href="centros.php";
                  } 
                  else{
                    document.location.href="planes.php";
                  }          
              }
              else{
                //$('#codigo_planes').val(result['codigo_redencion']);
                //$('#fRedes').submit();
                document.location.href='redes.php';
              }  
            
        }
        else{
          //MIXPANEL
          if (typeof mixpanel != "undefined") {
            mixpanel.track("Registration page viewed", {
              "Age": $("#itEdad").val(),
              "Gender": $("#itGenero").val()
            });
          }

          $('#codigo_redencion').val(result['codigo_redencion']);
          $('#modal-registro').modal("show");
          if (parseInt(result['show_legal_checks']) == 1){
            $('#check_legal1_reg').show();
          }
          else{
            $('#check_legal1_reg').hide();
          }
          if (parseInt(result['show_optin_brand']) == 1){
            $('#check_legal2_reg').show();
          }
          else{
            $('#check_legal2_reg').hide();
          }
          $('.btn').removeClass("loader");
          
        }
    }    
    else{
      document.location.href = result['redirect_codigos_ES']+'?codigo_redirect='+result['codigo_redencion'];
    } 
    
  } else if(result['error']) {
     $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
    if (result['error']['msg_error'] == '' || result['error']['msg_error'] == null){
      PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
    }
    else{
      PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
    }
    
  } else {
     $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
    PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
  }
};

function resultadoRegistro(obj,target, result, spinner_holder){
 // console.log(result);
  if (result['resultado'] == 'OK' ){

      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Register finished", {
          "Age": $("#itEdad").val(),
          "Gender": $("#itGenero").val(),
          "TLC opt in": $('#check-legal3').is(':checked')
        });
      }  

      //document.location.href="planes.php";
      $('#modal-registro').modal('hide'); 
      //console.log('oculta registro');
      $('#modal-validado').modal('show');   
      //$('#codigo_planes').val($('#codigo_redencion').val());
        if (parseInt(result['num_categorias']) == 1){
            var _catsel = result['categoria_sel'];//$( ".red-input" ).attr('id')+',';
            
            setCookie("tlcpromo_in", '', 365);
            setCookie("tlcpromo_out", '', 365);

            setCookie("tlcpromo_categorias", _catsel+',', 365);
                     
        }
        $('#numcategorias').val(result['num_categorias']); 
        $('#numsubcategorias').val(result['num_subcategorias']);  
        if (parseInt(result['num_subcategorias']) == 1){
            var _subcatsel = result['subcategoria_sel'];
            setCookie("tlcpromo_subcategorias", _subcatsel+',', 365);
        } 

    } else if(result['error']) {
    $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
      $('#modal-registro').modal('hide');  
      PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
  } else {
     $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
     $('#modal-registro').modal('hide'); 
    PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
  }
};

function respuestaBono(obj,target, result, spinner_holder){
  $('#modal-descargabono').modal('hide');
  if (result['resultado'] == 'OK' ){
      //PLG_showModalAlerta("OKOK", result['error']['msg_error']);
      $('#modal-bonook').modal('show');
      $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
      //MIXPANEL
      if (typeof mixpanel != "undefined") {
      mixpanel.track("Voucher downloaded");
      }  
  } else if(result['error']) {
    $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
      PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
  } else {
     $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
     PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
  }
};  

function envio_contacto(obj,target, result, spinner_holder){

  $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
  if (result['resultado'] == 'OK'){
       $('.contenedor-form').slideUp('fast', function(){
          $('.alerta-gracias').slideDown('slow', function(){
              $('.gracias-contacto').show();
          });

      });
    }else{
      PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
    }

};

function resultadoCookies(obj,target, result, spinner_holder){
  // console.log(result);
  if (result['resultado'] == 'OK' ){
    
    if ( result['codigoGA']!=''){
      //console.log('co::'+result['codigoGA']);
        if (result['codigoGA'].substr(0, 2) == 'UA'){
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
                //'<?=GA_CODE?>'
                  ga('create', result['codigoGA'], 'auto');
                  ga('send', 'pageview');

                  initGA();
              }    
            }
            else{
              (window,document,'script','//https://www.googletagmanager.com/gtag/js?id='+result['codigoGA'],'ga');
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());      
              gtag('config', result['codigoGA']);
            }      
    }
  
  /*if (result['resultado'] == 'OK' ){
    $("#modal-cookies").fadeOut();
    setCookie("tlcpromo-cookies-tecnica", "true", 365);//la técnica la aprobamos por js como hasta ahora
    
    if (result['estadisticas'] == 'si' && result['codigoGA']!=''){
      //console.log('co::'+result['codigoGA']);
        if (result['codigoGA'].substr(0, 2) == 'UA'){
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
                //'<?=GA_CODE?>'
                  ga('create', result['codigoGA'], 'auto');
                  ga('send', 'pageview');

                  initGA();
              }    
            }
            else{
              (window,document,'script','//https://www.googletagmanager.com/gtag/js?id='+result['codigoGA'],'ga');
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());      
              gtag('config', result['codigoGA']);
            }      
  }*/
}  
function initGA(){
  $.ajax({
    url: "ajax/ajax-ga.php",
    type: "POST",
    dataType: "json",
    success: function (response) {
      //console.log(response);
      if (response['codigoGA'] != '' ){
          if (response['codigoGA'].substr(0, 2) == 'UA'){
            $('.js-activarGA').on('click', function(){
              var pag = $(this).attr('data-pagina');
              var evento = $(this).attr('data-evento');
              ga('send', 'event', pag, 'CLICK', evento);
              //console.log('GA');
            });  
          }
          else{
            $('.js-activarGA').on('click', function(){
              var pag = $(this).attr('data-pagina');
              var evento = $(this).attr('data-evento');
              gtag('event', pag+'_CLICK_'+evento);
              //console.log('GT');
            });   
          }  
        }    
      },
      error: function (xhr, status) {
      },
      complete: function (xhr, status) {
      }

    });
  /*$.ajax({
    url: "ajax/ajax-ga.php",
    type: "POST",
    dataType: "json",
    success: function (response) {
      //console.log(response);
      if (response['codigoGA'] != '' && getCookie("tlcpromo-cookies-estadisticas")){
          if (response['codigoGA'].substr(0, 2) == 'UA'){
            $('.js-activarGA').on('click', function(){
              var pag = $(this).attr('data-pagina');
              var evento = $(this).attr('data-evento');
              ga('send', 'event', pag, 'CLICK', evento);
              //console.log('GA');
            });  
          }
          else{
            $('.js-activarGA').on('click', function(){
              var pag = $(this).attr('data-pagina');
              var evento = $(this).attr('data-evento');
              gtag('event', pag+'_CLICK_'+evento);
              //console.log('GT');
            });   
          }  
        }    
      },
      error: function (xhr, status) {
      },
      complete: function (xhr, status) {
      }

    });
    */    


}
